import React from 'react'
import BookingSection from "./book"

const CoverSection = ({ scroll, reset, setactive }) => {
    const scrollref = React.useRef()
    const [booking, setbooking] = React.useState(false)

    React.useEffect(() => {
        if (scroll) {
            // scrollref.current.scrollIntoView({ behavior: 'smooth' })
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
            reset(false)
        }
    }, [scroll])

    function onIntersection(entries, opts) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setactive("cover")
            }
        })
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(onIntersection, {
            root: null,
            threshold: 0.2
        })
        if (scrollref.current) observer.observe(scrollref.current)

        return () => {
            if (scrollref.current) observer.unobserve(scrollref.current)
        }
    }, [scrollref])

    return (
        <div ref={scrollref} className="flex w-full h-screen bg-[#0e7af7] text-[#ffffff] relative">
            <div className="h-full bg-cover">
            </div>
            <div className="w-full h-full absolute top-0 flex flex-col items-center justify-center z-10">
                <div className="flex w-full items-end justify-center mt-[120px]">
                    <div className="bg-[#1f2202] bg-opacity-60 p-[20px] text-[30px] md:text-[35px] h-fit text-center font-bold">
                        DCM&nbsp;Custom&nbsp;Painting&nbsp;LLC <br />
                        <span className="text-[20px] md:text-[22px]">Always&nbsp;one&nbsp;coat&nbsp;above&nbsp;the&nbsp;rest.</span>
                    </div>
                </div>
                <div className="flex w-full items-end justify-center">
                    <div className="bg-[#0e7af7] px-[15px] py-[10px] text-[18px] mt-[10px] h-fit mb-[20px] cursor-pointer" onClick={() => setbooking(true)}>
                        Book your free estimate now.
                    </div>
                </div>
            </div>
            <BookingSection booking={booking} setbooking={setbooking} />
        </div>
    )
}

export default CoverSection