import React from 'react'
import { RiCloseFill } from 'react-icons/ri'
import axios from 'axios'

export const data = {
    email: '',
    name: '',
    address: '',
    contact: '',
    services: '',
    message: ''
}

export function reducer(state, action) {
    switch (action.type) {
        case 'field':
            return {
                ...state,
                [action.name]: action.data
            }
        case 'clear':
            return {
                ...state,
                email: '',
                name: '',
                address: '',
                contact: '',
                services: '',
                message: ''
            }
    }
}

const BookingSection = ({ booking, setbooking }) => {
    const [state, dispatch] = React.useReducer(reducer, data)
    const [sending, setsending] = React.useState(false)
    const URL_ROOT = "https://syntaxworkgroup.net/_access"
    const [availed, setavailed] = React.useState([
        { service: "Exterior Painting", value: false },
        { service: "Interior Painting", value: false },
        { service: "Family Room Painting", value: false },
        { service: "Concrete Floor Painting", value: false },
        { service: "Commercial Interiors", value: false },
        { service: "Office Space", value: false },
        { service: "Cabinet Painting", value: false },
        { service: "Wallpaper Removal", value: false },
        { service: "Staining", value: false },
        { service: "Pressure Washing", value: false }
    ])
    const [current, setcurrent] = React.useState({ service: "", value: false })
    const values = state

    React.useEffect(() => {
        if (booking) reset()
    }, [booking])

    React.useEffect(() => {
        if (current) {
            let t = !current.value
            let av = availed
            let a = av.map(f => {
                if (f.service === current.service) {
                    f.value = t
                }
                return f
            })
            setavailed(a)
        }
    }, [current])

    function APPENDSTRING(val, str, delimiter = ", ") {
        if (val !== "") {
            return val + delimiter + str
        }
        return str
    }

    React.useEffect(() => {
        let services = ""
        availed.map(f => {
            if (f.value) services = APPENDSTRING(services, f.service, "-")
        })
        dispatch({ type: 'field', name: 'services', data: services })
    }, [availed])

    const valueChange = (e) => {
        const { name, value } = e.target
        dispatch({ type: 'field', name: name, data: value })
    }

    const emailChange = (e) => {
        const { name, value } = e.target
        dispatch({ type: 'field', name: name, data: value.toLowerCase() })
    }

    const mail = () => {
        if (sending) return
        setsending(true)
        axios['get'](`${URL_ROOT}/emailer.php`, {
            params: {
                i: "Online Booking",
                e: values.email,
                n: values.name,
                a: values.address,
                c: values.contact,
                s: values.services,
                m: values.message
            }
        }).then((ret) => {
            if (!ret.data.RESULT) {
                alert("Message failed")
                return
            }
            dispatch({ type: 'clear' })
            reset()
            setsending(false)
            alert("Booking sent!")
            setbooking(false)
        }).catch((err) => {
            console.log(err)
            setsending(false)
        })
    }

    function reset() {
        let av = availed
        let a = av.map(f => {
            f.value = false
            return f
        })
        setavailed(a)
    }

    return (
        (booking) ? (
            <div className="w-full h-full z-20 bg-[#020844] bg-opacity-50 flex items-center justify-center fixed top-0 left-0">
                <div className="w-full md:w-[500px] p-[20px] flex flex-col bg-[#ffffff] text-[#000000] shadow shadow-lg">
                    <div className="flex mb-[20px]">
                        <div className="font-bold">Booking:</div>
                        <div className="ml-auto cursor-pointer" onClick={() => setbooking(false)}>
                            <RiCloseFill />
                        </div>
                    </div>
                    <div className="flex flex-col px-[20px]">
                        <input type="text" name="name" value={values.name} onChange={valueChange} placeholder="Name" className=" mt-[10px] p-[10px] text-[14px] border border-1 border-[#c4c3c3] outline-none" required />
                        <input type="email" name="email" value={values.email} onChange={emailChange} placeholder="Email" className=" mt-[10px] p-[10px] text-[14px] border border-1 border-[#c4c3c3] outline-none" required />
                        <input type="text" name="address" value={values.address} onChange={valueChange} placeholder="Address" className=" mt-[10px] p-[10px] text-[14px] border border-1 border-[#c4c3c3] outline-none" required />
                        <input type="text" name="contact" value={values.contact} onChange={valueChange} placeholder="Contact No." className=" mt-[10px] p-[10px] text-[14px] border border-1 border-[#c4c3c3] outline-none" required />
                    </div>
                    <div className="flex px-[20px] text-[12px] pt-[10px]">Click to choose the services you want to avail.</div>
                    <div className="flex flex-wrap px-[20px] py-[10px]">
                        {
                            (availed && availed.length) ? (
                                availed.map((s, i) => (
                                    <div key={i} className={`p-[5px] rounded-[5px] mr-[5px] mt-[5px] cursor-pointer border border-1 border-[#0762e9] no-select ${s.value ? "bg-[#0762e9] text-[#ffffff]" : "bg-[#ffffff] text-[#0762e9]"}`} onClick={() => setcurrent(s)}>{s.service}</div>
                                ))
                            ) : null
                        }
                    </div>
                    <div className="flex flex-col px-[20px]">
                        <textarea name="message" cols="30" rows="8" value={values.message} onChange={valueChange} placeholder="Specifics/Remarks" className="mt-[10px] p-[10px] text-[14px] border border-1 border-[#c4c3c3] outline-none"></textarea>
                    </div>
                    <div className="flex px-[20px] justify-center mt-[20px]">
                        <input type="button" value="Send" className="w-[90px] rounded-[5px] mr-[10px] p-[10px] text-[14px] border border-1 border-[#c4c3c3] outline-none cursor-pointer bg-[#0762e9] text-[#ffffff]" onClick={() => mail()} />
                        <input type="button" value="Cancel" className="w-[90px] rounded-[5px] mr-[10px] p-[10px] text-[14px] border border-1 border-[#c4c3c3] outline-none cursor-pointer bg-[#fc0b0b] text-[#ffffff]" onClick={() => setbooking(false)} />
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default BookingSection