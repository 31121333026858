import React from 'react'
import Comment from "./comment"

const ClientSection = () => {
    const comments = [
        {
            message: "Absolutely Amazing! Dave and Rommel took all the stress away from my well overdue painting project. I did not have to worry about even the smallest detail. They automatically had it covered. They were very prompt, efficient, extremely meticulous, and budget-friendly. My walls/ceilings had many imperfections, water stains, and nail pops. It was a very overwhelming and tedious project requiring a lot of spackling/sanding and mess. I was blown away at how amazing everything looks, how quickly everything was completed, and absolutely No mess!!! I would highly recommend!!",
            name: "Theresa T.",
            dated: "03/09/2021",
            angi: true
        },
        {
            message: "I had several estimates. Dave came to my home and gave me an estimate and also some very helpful suggestions. I have several rooms painted along with trim and doors. He even replaced the door handles for me. He also cut a doorway under the steps to create a walk in clo set for extra storage. He put drywall and flooring in there. Wonderful job! More than I even expected. He and his staff are very professional and polite. He is true to his word and followed up afterwards to be sure I was satisfied.",
            name: "Elisabeth B.",
            dated: "01/20/2022",
            angi: false
        },
        {
            message: "I can’ t say enough good things about this company. I had my entire condo painted, including ceilings, trim and doors. David stayed in touch before, during and throughout the project, which was delayed at one point because I had Covid. The painter was professional, courteous, prompt, efficient and an excellent painter and completed the job in 6 days. The price was very reasonable for such a large job. Iâ ve had many positive comments about his work from friends and family. I would use this company again and definitely recommend them.",
            name: "Kathy H.",
            dated: "01/08/2022",
            angi: false
        }
    ]

    return (
        <div className="w-full p-[30px] text-[#000000] flex flex-col items-center pt-[70px] md:px-[100px] lg:px-[200px]">
            <div className="font-bold w-full flex justify-center text-[30px]">
                What Our Clients Say
            </div>
            {
                comments.map((comm, i) => (
                    <Comment key={i} message={comm.message} name={comm.name} dated={comm.dated} angi={comm.angi} />
                ))
            }
        </div>
    )
}

export default ClientSection