import React from 'react'
import logo from '../../../assets/logo.png'
import { GoLocation, GoMail } from 'react-icons/go'
import { BsTelephone } from 'react-icons/bs'
import { FiFacebook } from 'react-icons/fi'
import { Link } from "react-router-dom"

const HeaderSection = () => {
    return (
        <div className="flex px-[15px] py-[10px] h-[100px] bg-[#ffffff]">
            <div className="ml-[50px] h-full">
                <img src={logo} alt="" className="h-full" />
            </div>
            <div className="hidden lg:flex w-full py-[15px] flex-nowrap justify-end xl:justify-center text-[12px]">
                <div className="flex mr-[5px] ml-[10px] xl:mr-[20px] xl:ml-[20px] items-center">
                    <div className="w-[30px] h-[30px] rounded-[50%] bg-[#0e7af7] flex flex-none mr-[10px] text-[#ffffff] items-center justify-center text-[20px]">
                        <GoLocation />
                    </div>
                    <div>750 CONCOURSE CIRCLE <br /> SUITE 103 Unit #392 <br />Baltimore, MD 21220</div>
                </div>
                <div className="flex mr-[5px] ml-[10px] xl:mr-[20px] xl:ml-[20px] items-center">
                    <div className="w-[30px] h-[30px] rounded-[50%] bg-[#0e7af7] flex flex-none mr-[10px] text-[#ffffff] items-center justify-center text-[18px]">
                        <BsTelephone />
                    </div>
                    <div>
                        <a href="tel:(410) 842-3706"><p className="call-button">(410) 842-3706</p></a>
                    </div>
                </div>
                <div className="flex mr-[5px] ml-[10px] xl:mr-[20px] xl:ml-[20px] items-center">
                    <div className="w-[30px] h-[30px] rounded-[50%] bg-[#0e7af7] flex flex-none mr-[10px] text-[#ffffff] items-center justify-center text-[20px]">
                        <GoMail />
                    </div>
                    <div>
                        {/* <a href="mailto:dcmielke@gmail.com">
                            dcmielke@gmail.com 
                        </a>
                        <br /> */}
                        <a href="mailto:dcmielke@dcmcustompainting.com">
                            dcmielke@dcmcustompainting.com 
                        </a>
                    </div>
                </div>
                <div className="flex mr-[5px] ml-[10px] xl:mr-[20px] xl:ml-[20px] items-center">
                    <a href="https://www.facebook.com/DCMCustompaint" target="_blank" className="flex items-center">
                        <div className="w-[30px] h-[30px] rounded-[50%] bg-[#0e7af7] flex flex-none mr-[10px] text-[#ffffff] items-center justify-center text-[20px]">
                            <FiFacebook />
                        </div>
                        <div>DCM Custom Painting LLC</div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default HeaderSection