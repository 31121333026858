import React from 'react'
import { useGoogleMaps } from "react-hook-google-maps"

const uluru = { lat: -25.344, lng: 131.036 };

const CitiesSection = () => {

    return (
        <div className="w-full p-[30px] my-[70px] items-center flex">
            {/* <div className="w-[0px] xl:w-full">&nbsp;</div> */}
            <div className="w-full flex flex-col items-center">
                <div className="font-bold w-full flex justify-center text-[30px]">
                    Cities We Serve
                </div>
                <div className="w-full lg:w-[900px] h-[500px] bg-[#037e28] mt-[20px]">
                    <div className="w-full h-full bg-[#089b34] flex justify-center items-center">
                        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1Yc68T8pPlJBQf6GPuHThTe9vjDJHpAsg&ehbc=2E312F&z=10" className="w-full h-full"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CitiesSection