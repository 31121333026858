import './styles/output.css'
import './styles/index.css'
import RouterRoot from './routes/rtroot';

function App() {
    return (
        <RouterRoot />
    )
}

export default App;
