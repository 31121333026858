import React from 'react'
import angi from '../../../assets/angi_cert.png'
import cert from '../../../assets/rate_cert.png'

const Comment = ({ message, name, dated, angi }) => {
    return (
        <div className="w-full text-[20px] mt-[40px] pb-[20px] flex flex-col ">
            <div className="relative px-[30px]">
                {message}
                <span className="qoute-font text-[60px] absolute top-0 left-0 mt-[-15px] text-[#c0bdbd] no-select">&#8220;</span>
                <span className="qoute-font text-[60px] absolute bottom-0 mt-[-20px] text-[#c0bdbd] ml-[10px] no-select">&#8222;</span>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className={`w-[300px] ${angi ? "h-[90px]" : "h-[130px]"} flex flex-none mt-[20px] ${angi ? "bg-angi" : "bg-rate"}`}>
                </div>
                <div className="md:ml-auto flex flex-col">
                    <div className="ml-[20px] font-bold mt-[20px]">
                        {name}
                    </div>
                    <div className="ml-[20px]">{dated}</div>
                </div>
            </div>
        </div>
    )
}

export default Comment