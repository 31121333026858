import React from 'react'
import certified from '../../../assets/certified.png'
import angi from '../../../assets/angi.png'

const IntroSection = ({ scroll, reset, setactive }) => {
    const scrollref = React.useRef()

    React.useEffect(() => {
        if (scroll) {
            scrollref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })
            reset(false)
        }
    }, [scroll])

    function onIntersection(entries, opts) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setactive("intro")
            }
        })
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(onIntersection, {
            root: null,
            threshold: 0.1
        })
        if (scrollref.current) observer.observe(scrollref.current)

        return () => {
            if (scrollref.current) observer.unobserve(scrollref.current)
        }
    }, [scrollref])

    return (
        <>
            <div ref={scrollref} className="w-full pt-[180px]">
                <div className="md:px-[30px] pb-[100px] flex flex-col items-center">
                    <div className="font-bold w-full flex justify-center text-[45px]">
                        Why Choose DCM?
                    </div>
                    <div className="text-[25px] mt-[40px] px-[75px]">
                        <p className="">
                            DCM Custom Painting LLC employs the most talented professionals to ensure superb customer satisfaction with the completed final project.  We listen to our customers and provide high quality services and advice to them, always with a personal touch.  We treat the customer’s home like own, with respect, courtesy, and professionalism.
                        </p>
                    </div>
                    <div className="text-[25px] mt-[40px] mb-[80px] flex flex-col md:flex-row px-[75px]">
                        <div className="w-full">
                            <div className="flex mb-[35px]">
                                <span className="text-[#068626] mr-[10px]">&#10004;</span>
                                <span>2-Year Warranty</span>
                            </div>
                            <div className="flex mb-[35px]">
                                <span className="text-[#068626] mr-[10px]">&#10004;</span>
                                <span>Licensed and Insured <br />
                                    MHIC License: 113744</span>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex mb-[15px]">
                                <span className="text-[#068626] mr-[10px]">&#10004;</span>
                                <span>Our employees are rigorously selected,
                                    background checked and drug tested.</span>
                            </div>
                            <div className="flex">
                                <span className="text-[#068626] mr-[10px]">&#10004;</span>
                                <span>We use high quality paint and materials
                                    by Sherwin Williams and Benjamin Moore.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full text-[#ffffff] bg-milestone">
                {/* bg-[#f6583c] */}
                    <div className="bg-[#e4c205] py-[70px] bg-opacity-80 flex">
                        <div className="w-full flex flex-wrap place-content-between items-center justify-center px-[250px]">
                            <div className="flex flex-col items-center flex-none w-[300px] my-[20px]">
                                <div className="font-bold text-[50px]">30 years</div>
                                <p className="text-center">of home improvement</p>
                            </div>
                            <div className="flex flex-col items-center flex-none w-[300px] my-[20px]">
                                <div className="font-bold text-[50px]">500+</div>
                                <p className="text-center">happy customers</p>
                            </div>
                            <div className="flex flex-col items-center flex-none w-[300px] my-[20px]">
                                <div className="font-bold text-[50px] flex items-center">5.0 <span className="ml-[10px] text-[35px]">&#9733;</span></div>
                                <p className="text-center">on Angi (formerly Angie's List)</p>
                            </div>
                            <div className="flex flex-col items-center flex-none w-[300px] my-[20px]">
                                <div className="font-bold text-[50px]">96%</div>
                                <p className="text-center">recommended on HomeAdvisor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IntroSection